import {Component, OnInit} from '@angular/core';
import {HttpEventType} from "@angular/common/http";
import {forkJoin, Subscription} from "rxjs";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxFileDropEntry} from 'ngx-file-drop';
import {Document} from '../types';
import {ApiService} from "../api.service";
import {extendWithFile} from "../helpers";

type UploadedDocument = NgxFileDropEntry & Document & { progress: number, file?: File, changed?: boolean, sub?: Subscription };

@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.css']
})
export class UploadDocumentModalComponent implements OnInit {
  files?: UploadedDocument[];
  renamingInProgress: boolean = false;
  public documentsPackageId!: number;

  constructor(private api: ApiService, private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  fileDrop(files: NgxFileDropEntry[]) {
    const mappedFiles = files.map(file => ({
      title: file.fileEntry.name,
      DocumentsPackageId: this.documentsPackageId,
      progress: 0,
      consolidated: false,
      ...file,
    }));

    this.startUploadingFiles(mappedFiles);

    this.files = this.files?.concat(mappedFiles) || mappedFiles;
  }

  changed(file: UploadedDocument) {
    file.changed = true;
  }

  submit() {
    const changedFiles = this.files?.filter(f => f.changed && f.id);
    if (changedFiles?.length && changedFiles.length > 0) {
      this.renamingInProgress = true;

      const renameObservables = changedFiles.map(file => this.api.updateDocument({
        id: file.id!,
        title: file.title,
        consolidated: file.consolidated,
      }));

      forkJoin(renameObservables).subscribe(() => {
        this.renamingInProgress = false;
        this.activeModal.close();
      });
    } else {
      this.activeModal.close();
    }
  }

  removeFile(file: UploadedDocument) {
    if (file.sub!.closed) {
      this.api.removeDocument(file.id!)
        .subscribe(() => {
          const idx = this.files!.indexOf(file);
          this.files!.splice(idx, 1);
        });
    } else {
      file.sub!.unsubscribe();
      const idx = this.files!.indexOf(file);
      this.files!.splice(idx, 1);
    }
  }

  private async startUploadingFiles(mappedFiles: UploadedDocument[]) {
    await extendWithFile(mappedFiles);

    mappedFiles[0].sub = this.api.uploadTemplates(mappedFiles as (UploadedDocument & { file: File })[])
      .subscribe((response) => {
        if (response.type === HttpEventType.UploadProgress) {
          mappedFiles[0].progress = response.loaded / response.total! * 100;
        }

        if (response.type === HttpEventType.Response) {
          mappedFiles[0].progress = 100;
          mappedFiles[0].id = response.body!.id;
        }
      });
  }
}
