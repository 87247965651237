<ng-container *ngIf="pkg; else loading">
  <h1>{{ pkg.title }}</h1>
  <h2>Шаблоны</h2>
  <table class="table">
    <thead>
    <tr>
      <td>Документ</td>
      <td class="text-center">Сводный</td>
      <td><button class="btn btn-sm btn-success" (click)="addDocument()"><i class="bi bi-plus-lg"></i></button></td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let doc of pkg.documents">
      <td>
        <ng-container *ngIf="!doc.isEditing; else input">{{ doc.title }}</ng-container>
        <ng-template #input>
          <input type="text" class="form-control form-control-sm" [(ngModel)]="doc.title" (keydown)="inputKeydown($event, doc)">
        </ng-template>
      </td>
      <td>
        <input type="checkbox" class="form-control form-control-sm" [(ngModel)]="doc.consolidated" [disabled]="!doc.isEditing">
      </td>
      <td>
        <app-editing [item]="doc" (remove)="removeDocument($event)" (save)="saveDocument($event)"></app-editing>
      </td>
    </tr>
    <tr *ngIf="pkg?.documents?.length === 0">
      <td colspan="2">
        Документы ещё не загружены. Чтобы загрузить первый документ, нажмите на
        <i class="bi bi-plus-lg"></i> в заголовке таблицы.
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>
<ng-template #loading><app-loading></app-loading></ng-template>
