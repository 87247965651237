<div class="sidebar-sticky">
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" routerLink="/debtors">База должников</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/packages">Пакеты документов</a>
    </li>
  </ul>
</div>
