import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsPackagesComponent } from './documents-packages/documents-packages.component';
import { DocumentsPackageComponent } from './documents-package/documents-package.component';

const routes: Routes = [
  { path: 'packages', component: DocumentsPackagesComponent },
  { path: 'packages/:id', component: DocumentsPackageComponent },
  { path: 'debtors', loadChildren: () => import('./debtors/debtors.module').then(m => m.DebtorsModule) },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [ RouterModule ],
})
export class RoutingModule { }
