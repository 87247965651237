import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType} from "@angular/common/http";
import {
  Debtor,
  DebtorsUploadingResults,
  Document,
  DocumentsPackage,
  DocumentsPackageFull,
  QueryParams,
  Response
} from "./types";
import {BehaviorSubject, Observable, Subject, Subscription, tap} from "rxjs";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  readonly backendUrl = environment.backendUrl;

  documentsPackages = new BehaviorSubject<Response<DocumentsPackage> | null>(null);
  documents: Subject<Response<Document>> = new Subject();

  constructor(private http: HttpClient) { }

  getDocumentsPackages(): Observable<Response<DocumentsPackage> | null> {
    if (!this.documentsPackages.observed) {
      this.getDocumentsPackages_();
    }
    return this.documentsPackages.asObservable();
  }

  removeDocument(id: number): Observable<void> {
    return this.http.delete<void>(`${this.backendUrl}/api/templates.php?id=${id}`)
      .pipe(tap(() => this.getDocumentTemplates_()));
  }

  getDocuments(): Observable<Response<Document>> {
    if (!this.documents.observed) {
      this.getDocumentsPackages_();
    }
    return this.documents.asObservable();
  }

  getDebtors(queryParams?: QueryParams): Observable<Response<Debtor>> {
    return this.http.get<Response<Debtor>>(ApiService.bindQueryParams(`${this.backendUrl}/api/debtors.php`, queryParams));
  }

  getDocumentsPackage(id: number): Observable<DocumentsPackageFull> {
    return this.http.get<DocumentsPackageFull>(`${this.backendUrl}/api/packages.php?id=${id}`);
  }

  private getDocumentsPackages_(): Subscription {
    return this.http.get<Response<DocumentsPackage>>(`${this.backendUrl}/api/packages.php`)
      .subscribe(response => this.documentsPackages.next(response));
  }

  private getDocumentTemplates_(): Subscription {
    return this.http.get<Response<Document>>(`${this.backendUrl}/api/templates.php`)
      .subscribe(response => this.documents.next(response));
  }

  updateDocumentsPackage(pkg: DocumentsPackage): Observable<void> {
    return this.http.put<void>(`${this.backendUrl}/api/packages.php?id=${pkg.id}`, pkg)
      .pipe(tap(() => this.getDocumentsPackages_()));
  }

  removeDocumentsPackage(pkg: DocumentsPackage) {
    return this.http.delete<void>(`${this.backendUrl}/api/packages.php?id=${pkg.id}`)
      .pipe(tap(() => this.getDocumentsPackages_()));
  }

  createDocumentsPackage(pkg: DocumentsPackage) {
    return this.http.post<void>(`${this.backendUrl}/api/packages.php`, pkg)
      .pipe(tap(() => this.getDocumentsPackages_()));
  }

  uploadTemplates(mappedFiles: ({ file: File } & Document)[]): Observable<HttpEvent<Document>> {
    const formData = new FormData();
    const file = mappedFiles[0];

    formData.append('file', file.file, file.file.name);
    formData.append('title', file.title);
    formData.append('DocumentsPackageId', file.DocumentsPackageId.toString());

    return this.http.post<Document>(`${this.backendUrl}/api/templates.php`, formData, {
      reportProgress: true,
      observe: 'events',
    })
      .pipe(tap((event) => {
        if (event.type === HttpEventType.Response) this.getDocumentTemplates_();
      }));
  }

  uploadSpreadsheet(mappedFile: { file: File }): Observable<HttpEvent<DebtorsUploadingResults>> {
    const formData = new FormData();
    formData.append('file', mappedFile.file, mappedFile.file.name);
    return this.http.post<DebtorsUploadingResults>(`${this.backendUrl}/api/debtors.php`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateDocument(doc: Partial<Document> & { id: number }): Observable<Document> {
    return this.http.put<Document>(`${this.backendUrl}/api/templates.php?id=${doc.id}`, doc)
      .pipe(tap(() => this.getDocumentTemplates_()));
  }

  generateDocuments(documentsPackageId: number, additionalData: any, debtorsIds: number[]) {
    const data = {
      documentsPackageId,
      debtorsIds,
      additionalData,
    };

    return this.http.post(`${this.backendUrl}/api/generate.php`, data, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  saveDebtors(editedDebtors: Debtor[]): Observable<any> {
    return this.http.put(`${this.backendUrl}/api/debtors.php?bulk=true`, editedDebtors);
  }

  private static bindQueryParams(url: string, queryParams?: QueryParams): string {
    if (!queryParams) return url;

    const paramsString = Object.keys(queryParams)
      .filter(key => !!queryParams[key])
      .map(key => `${key}=${queryParams[key]}`).join('&');
    if (paramsString.length === 0) {
      return url;
    }

    return `${url}?${paramsString}`;
  }
}
