<div class="modal-header">
  Вы можете загрузить один или несколько документов
</div>
<div class="modal-body">
  <ngx-file-drop (onFileDrop)="fileDrop($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      Перетащите файл сюда или нажмите
      <button class="ml-1 btn btn-sm btn-primary" type="button" (click)="openFileSelector()">Выбрать файл</button>
    </ng-template>
  </ngx-file-drop>

  <div class="mt-3 container" *ngIf="files">
    <div class="row" *ngFor="let file of files">
      <div class="col">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="file.title" (ngModelChange)="changed(file)" placeholder="Название документа">
      </div>
      <div class="col">
        <label>
          <input type="checkbox" class="form-control form-control-sm" [(ngModel)]="file.consolidated" (ngModelChange)="changed(file)">
          Сводный
        </label>
      </div>
      <div class="col">
        <ngb-progressbar type="success" textType="white" [value]="file.progress" [showValue]="true"></ngb-progressbar>
      </div>
      <div>
        <div class="btn btn-sm btn-danger" (click)="removeFile(file)">
          <i class="bi bi-trash"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-primary" (click)="submit()">
    <ng-container *ngIf="!renamingInProgress; else loading">
      <i class="bi bi-check-lg"></i>ОК
    </ng-container>
    <ng-template #loading>
      <i class="bi bi-hourglass-split"></i>Подождите...
    </ng-template>
  </button>
</div>
