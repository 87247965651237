<nav class="navbar navbar-dark bg-dark sticky-top flex-md-nowrap p-o shadow">
  <a href="/" class="navbar-brand mr-0 pb-0 pt-0">CompanyName</a>
  <ul class="nav mr-auto">
    <li class="nav-item">
      <a class="nav-link" routerLink="/debtors">База должников</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/packages">Пакеты документов</a>
    </li>
  </ul>
</nav>
<div class="container-fluid">
  <div class="row">
    <div class="col-12 ml-sm-auto px-md-4 pt-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
