import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RoutingModule } from './routing.module';
import { AppComponent } from './app.component';
import { DocumentsPackagesComponent } from './documents-packages/documents-packages.component';
import { FormsModule } from '@angular/forms';
import { DocumentsPackageComponent } from './documents-package/documents-package.component';
import { EditingComponent } from './editing/editing.component';
import { UploadDocumentModalComponent } from './upload-document-modal/upload-document-modal.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SidebarComponent } from './sidebar/sidebar.component';
import {HttpClientModule} from "@angular/common/http";
import {SharedModule} from "./shared/shared.module";

@NgModule({
  declarations: [
    AppComponent,
    DocumentsPackagesComponent,
    DocumentsPackageComponent,
    EditingComponent,
    UploadDocumentModalComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RoutingModule,
    NgxFileDropModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
