import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Editing } from '../types';

@Component({
  selector: 'app-editing',
  templateUrl: './editing.component.html',
  styleUrls: ['./editing.component.css']
})
export class EditingComponent<T extends Editing> implements OnInit {
  @Input() item?: T;

  @Output() save: EventEmitter<T> = new EventEmitter();
  @Output() remove: EventEmitter<T> = new EventEmitter();
  @Output() cancel: EventEmitter<T> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
