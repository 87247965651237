import { Component, OnInit } from '@angular/core';
import { Document, DocumentsPackageFull, Editing, Existing } from '../types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadDocumentModalComponent } from '../upload-document-modal/upload-document-modal.component';
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../api.service";

@Component({
  selector: 'app-documents-package',
  templateUrl: './documents-package.component.html',
  styleUrls: ['./documents-package.component.css']
})
export class DocumentsPackageComponent implements OnInit {
  pkg?: DocumentsPackageFull<Existing<Document> & Editing>;
  pkgId?: number;

  constructor(private api: ApiService, private route: ActivatedRoute, private modal: NgbModal) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.pkgId = +params['id'];
      this.fetchPackage();
    });
  }

  removeDocument(doc: Existing<Document>) {
    this.api.removeDocument(doc.id!)
      .subscribe(() => {
        this.fetchPackage();
      });
  }

  saveDocument(doc: Existing<Document>) {
    this.api.updateDocument(doc)
      .subscribe(() => {
        this.fetchPackage();
      });
  }

  addDocument() {
    const modalRef = this.modal.open(UploadDocumentModalComponent);
    (<UploadDocumentModalComponent>modalRef.componentInstance).documentsPackageId = this.pkgId!;

    modalRef.result
      .then(() => this.fetchPackage())
      .catch(() => this.fetchPackage());
  }

  inputKeydown($event: KeyboardEvent, doc: Existing<Document> & Editing) {
    if ($event.key === 'Enter') {
      doc.isEditing = false;
      this.saveDocument(doc);
    }
  }

  private fetchPackage() {
    if (!this.pkgId) {
      throw new Error('pkgId is undefined');
    }
    this.api.getDocumentsPackage(this.pkgId)
      .subscribe((pkg) => this.pkg = pkg);
  }
}
