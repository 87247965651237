<h1>Пакеты документов</h1>

<table class="table" *ngIf="packages; else loading">
  <thead>
  <tr>
    <th>Название пакета</th>
    <th>Число документов</th>
    <th><button class="btn btn-sm btn-success" (click)="addPackage()"><i class="bi bi-plus-lg"></i></button></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let pkg of packages">
    <td>
      <ng-container *ngIf="!pkg.isEditing; else inputField">
        {{ pkg.title }}
      </ng-container>
      <ng-template #inputField>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="pkg.title" (keydown)="inputKeydown($event, pkg)">
      </ng-template>
    </td>
    <td>{{ pkg.documentsCount }}</td>
    <td>
      <button class="btn btn-sm btn-success" [routerLink]="[pkg.id]"><i class="bi bi-box-arrow-in-right"></i></button>
      <app-editing [item]="pkg" (cancel)="cancelPackage(pkg)" (remove)="removePackage($event)" (save)="savePackage($event)"></app-editing>
    </td>
  </tr>
  </tbody>
</table>

<ng-template #loading>Загрузка...</ng-template>
