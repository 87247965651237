import {NgxFileDropEntry} from "ngx-file-drop";

export function extendWithFile<T extends NgxFileDropEntry & { file?: File }>(files: T[]) {
  return Promise.all(
    files
      .filter(f => f.fileEntry.isFile)
      .map(async (file) => {
        file.file = await new Promise((resolve) => (<FileSystemFileEntry>file.fileEntry).file(resolve));
      }),
  );
}
