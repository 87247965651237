import {Component, OnDestroy, OnInit} from '@angular/core';
import { DocumentsPackage, Editing } from '../types';
import {ApiService} from "../api.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-documents-packages',
  templateUrl: './documents-packages.component.html',
  styleUrls: ['./documents-packages.component.css']
})
export class DocumentsPackagesComponent implements OnInit, OnDestroy {
  private packages$?: Subscription;
  packages?: (DocumentsPackage & Editing)[];

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.packages$ = this.api.getDocumentsPackages()
      .subscribe((response) => {
        if (response) {
          this.packages = response.rows;
        }
      });
  }

  ngOnDestroy() {
    this.packages$!.unsubscribe();
  }

  savePackage(pkg: DocumentsPackage) {
    if (pkg.id) {
      this.api.updateDocumentsPackage(pkg)
        .subscribe(() => {});
    } else {
      this.api.createDocumentsPackage(pkg)
        .subscribe(() => {});
    }
  }

  removePackage(pkg: DocumentsPackage) {
    this.api.removeDocumentsPackage(pkg)
      .subscribe(() => {});
  }

  addPackage() {
    this.packages?.unshift({
      documentsCount: 0,
      isEditing: true,
      title: '',
    });
  }

  inputKeydown($event: KeyboardEvent, pkg: DocumentsPackage & Editing) {
    if ($event.key === 'Enter') {
      pkg.isEditing = false;
      this.savePackage(pkg);
    }
  }

  cancelPackage(pkg: DocumentsPackage & Editing) {
    if (!pkg.id) {
      const idx = this.packages!.indexOf(pkg);
      this.packages!.splice(idx, 1);
    }
  }
}
